<template>
  <div>
    <v-row
      justify="center"
      align="center"
      no-gutters
      style="height: 48px;background-color: white;border-radius: 10px"
      v-toucha:swipe="swipe">

      <v-btn v-if="$route.name === 'Home'" @click="toggleLeftPanel" icon class="ml-2" retain-focus-on-click plain>
        <v-icon v-if="showLeftPanel && !isMobile" color="primary">far fa-chevron-left</v-icon>
        <v-icon v-if="showLeftPanel && isMobile" color="primary">far fa-chevron-down</v-icon>
        <v-icon v-else-if="!showLeftPanel && !isMobile" color="primary">far fa-chevron-down</v-icon>
        <v-icon v-else-if="!showLeftPanel && isMobile" color="primary" size="22">fas fa-bars</v-icon>
      </v-btn>

      <v-btn v-else @click="goBack" icon class="ml-2" retain-focus-on-click plainz>
        <v-icon color="primary">far fa-chevron-left</v-icon>
      </v-btn>

      <!-- Search bar -->
      <SearchBar
        :value="search"/>

      <!-- Record microphone -->
      <v-btn v-if="!isRecording" icon width="40" :disabled="recordEnabled == null" class="mx-1" @click="startRecording">
        <v-icon color="primary">far fa-microphone</v-icon>
      </v-btn>

      <v-btn v-else-if="isRecording" icon width="40" class="mx-1" @click="stopRecording">
        <v-icon color="primary">fas fa-microphone</v-icon>
      </v-btn>

      <v-divider vertical />

      <!-- Create favorite -->
      <v-btn width="40" class="mx-1" v-if="$session.exists() && $route.query.q && $route.query.coords" icon @click="showCreateFavorite">
        <v-icon color="primary">far fa-heart</v-icon>
      </v-btn>

      <v-divider vertical />

      <!-- Btn not connected -->
      <v-btn v-if="!$session.exists()"  @click="goToLogin" icon width="40" class="mx-1">
        <img width="25" src="@/assets/icons/controls/user_off.svg" />
      </v-btn>

      <!-- Menu connected -->
      <v-btn
        v-if="$session.exists()"
        icon
        width="48"
        class="mx-1"
        @click="goToProfile"
      >
        <img width="25" src="@/assets/icons/controls/user_on.svg" />
      </v-btn>
    </v-row>

    <!-- Create Favorite Dialog -->
    <CreateFavoriteDialog
      v-if="showCreateFavoriteDialog"
      :coordinates="$store.getters['coords']"
      :value="showCreateFavoriteDialog"
      @close="(needRefresh) => closeCreateFavoriteDialog(needRefresh)"
    />

  </div>
</template>

<script>

export default {
  name: "SearchControl",

  mixins: [],

  components: {
    CreateFavoriteDialog: () => import('@/components/Account/Dialogs/CreateFavoriteDialog'),
    SearchBar: () => import("@/components/Common/Controls/SearchBarGoogleApiCustom")
  },

  data() {
    return {
      search: null,
      searchModel: null,
      searchItems: [],
      searchIsLoading: null,
      device: null,
      recorder: null,
      isRecording: false,
      recordEnabled: true,
      menu: false,
      showCreateFavoriteDialog: false,
      stream: null,
      timeoutRecording: null
    }
  },

  mounted() {
    if (this.$route.query.q) {
      this.search = {label: this.$route.query.q};
      //if (!!this.search)
        //this.searchQuery();
    }
  },

  methods: {
    clearSearch() {
      this.search = "";
      this.onSearch();
    },

    onSearch() {

      if (this.$route.name === "Home" && this.$route.query.q === this.search)
        return;

      if (this.$route.name === "Home")
        this.$router.replace({query: {q: this.search}});
      else
        this.$router.push({name: 'Home', query: {q: this.search}})
    },

    startRecording() {
      this.device = navigator.mediaDevices.getUserMedia({audio: true});
      this.device
        .then((stream) => {

          this.timeoutRecording = setTimeout(() => {
            this.stopRecording();
          }, 10000);

          this.stream = stream;
          this.recordEnabled = true;
          this.recorder = new MediaRecorder(stream);
          this.recorder.addEventListener('dataavailable', this.onRecordingReady);
          this.isRecording = true;
          this.recorder.start();
        })
        .catch(() => {
          this.recordEnabled = false;
          this.$store.commit("toast/showError", this.$t('view.search.errors.MICRO_DISABLED'));
        });
    },

    stopRecording() {
      clearTimeout(this.timeoutRecording);
      console.log("STOP");
      this.recorder.stop();
      this.isRecording = false;
      if (this.stream) {
        for (let i = 0; i < this.stream.getTracks().length; i++)
          this.stream.getTracks()[i].stop();
      }
    },

    onRecordingReady(data) {
      if (data.data.size >= 10000) {
        console.log('The blob data:', data);

        const formData = new FormData();
        formData.append("file", data.data);

        this.$http.post("speech-to-text/file", formData, {
          headers: {
            Authorization: "Bearer " + this.$session.get('jwt'),
            'content-type': 'multipart/form-data'
          }
        })
          .then(res => {
            if (!!res.data.result) {
              if (this.$route.name === 'Home')
                this.$router.replace({query: {q: res.data.result}});
              else
                this.$router.push({name: 'Home', query: {q: res.data.result}});
            }
            else
              this.$store.commit("toast/showError", this.$t('view.search.errors.NO_RESULT'));
          })
          .catch(err => {
            console.log(err);
          })
      }
      else
        this.$store.commit("toast/showError", this.$t("view.search.errors.NO_RESULT"));
    },

    toggleLeftPanel() {

      this.$store.commit("setShowLeftPanel", !this.$store.getters["showLeftPanel"]);
    },

    goBack() {
      //window.history.back();
      this.$router.push({name: "Home", query: this.$route.query});
    },

    goToLogin() {
      if (this.$route.name !== 'Login')
        this.$router.push({name: 'Login'})
    },

    logout() {
      this.$store.dispatch("logout");
      this.state = 0;
      if (this.$route.name !== "Home")
        this.$router.push({name: "Home"});
      else
        this.$router.go();

    },

    goToProfile() {
      if (this.$route.name !== 'Profile')
        this.$router.push({name: 'Profile'});
    },

    showCreateFavorite() {
      this.showCreateFavoriteDialog = true;
    },
    closeCreateFavoriteDialog(needRefresh = false) {

      this.showCreateFavoriteDialog = false;
      if (needRefresh) {
        window.location.reload();
      }
    },

    swipe(direction) {
      //console.log(direction);
      this.$store.commit("setShowLeftPanel", direction === 'top')
    }

  },

  watch: {
    '$route.query.q'(newValue, oldValue) {
      console.log(newValue);
      if (newValue !== this.search && !!newValue) {
        //this.search = {label: newValue};
      }
    }
  },

  computed: {
    showLeftPanel() {
      return this.$store.getters["showLeftPanel"];
    },

    isMobile() {
      return this.$store.getters['isMobile']
    }
  }

}
</script>

<style scoped>

</style>